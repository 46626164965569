import Cookies from "universal-cookie";
import { GetUserAxiosResponse, GetWarehouseAxiosResponse } from "../redux/types/AuthTypes/auth.types";
import { instance } from "./authAPI";

export const loginAPI = {
    getUser(cookies: Cookies, id: string) {
        return instance.get<unknown, GetUserAxiosResponse>(`/users/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getWarehouse(cookies: Cookies) {
        return instance.get<unknown, GetWarehouseAxiosResponse>(`/tenants/tenant-type/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
