import Cookies from "universal-cookie";
import { ShippedAmazonAxiosResponse } from "../redux/types/SettingsTypes/settings.types";
import { instance } from "./authAPI";

export const shipedAmazonProduct = {
    getShipedAmazonProduct(cookies: Cookies, date_from?: string, date_to?: string) {
        return instance.get<unknown, ShippedAmazonAxiosResponse>(
            `/shipments/user/dashboard/outgoing-shipment-history/?${date_from ? `&date_from=${date_from}` : ""}${date_to ? `&date_to=${date_to}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getShipedAmazonProductAll(cookies: Cookies, items: number, offset: number, searchTerm?: string, date_from?: string, date_to?: string) {
        return instance.get<unknown, ShippedAmazonAxiosResponse>(
            `/shipments/user/dashboard/outgoing-shipment-history/?limit=${items}&offset=${offset}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}&date_from=${date_from}&date_to=${date_to}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};
