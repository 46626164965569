import { ButtonGroup } from "react-bootstrap";
import InactiveButton from "./InactiveButton";
import usa_icon from "../../../../assets/img/MarketsButtons/usa.png";
import great_britain_icon from "../../../../assets/img/MarketsButtons/great-britain.png";

export interface CompactMarketsButtonsPropsType {
    tabState: string;
    setTabState: (data: string) => void;
}

const CompactMarketsButtons = ({ tabState, setTabState }: CompactMarketsButtonsPropsType) => {
    const onChangeTab = (state: string) => {
        if (state !== tabState) {
            setTabState(state);
        }
    };

    return (
        <div className="d-flex align-items-center justify-content-start">
            <img src={usa_icon} alt="" className="mr-2" />
            <ButtonGroup className="h-25px">
                <InactiveButton dataTestid="btn_US" text="US" styles={tabState !== "US" ? "inactive-button-group" : ""} onClick={() => onChangeTab("US")} />
                <InactiveButton dataTestid="btn_UK" text="UK" styles={tabState !== "UK" ? "inactive-button-group" : ""} onClick={() => onChangeTab("UK")} />
            </ButtonGroup>
            <img src={great_britain_icon} alt="" className="ml-2" />
        </div>
    );
};

export default CompactMarketsButtons;
