import Cookies from "universal-cookie";
import {
    UserBillingAxiosResponse,
    BillingReportAxiosResponse,
    CreateBillingReportFileAxiosResponse,
    CreateBillingReportFileRequestData,
    TGetTenantRatesInfo,
    TPostChargeForPaymentGroup,
    TUserPaymentsDue,
} from "../redux/types/SettingsTypes/settings.types";
import { instance } from "./authAPI";

export const userBilling = {
    getUserBilling(cookies: Cookies, status: string, sortBy?: string | null, searchValue?: string | null, dateFrom?: string, dateTo?: string) {
        return instance.get<unknown, UserBillingAxiosResponse>(
            `/payments/user-billing/${status === "succeeded" ? "?status=succeeded" : "?status=requires_payment_method&status=requires_action&status=stripe_not_connected"}${sortBy ? "&payment_type=" + sortBy : ""}${searchValue ? "&search=" + window.encodeURIComponent(searchValue) : ""}${dateFrom && dateTo ? `&date_from=${dateFrom}&date_to=${dateTo}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getUserBillingAll(cookies: Cookies, status: string, items: number, offset: number, sortBy?: string | null, searchValue?: string | null, dateFrom?: string, dateTo?: string) {
        return instance.get<unknown, UserBillingAxiosResponse>(
            `/payments/user-billing/?limit=${items}&offset=${offset}${status === "succeeded" ? "&status=succeeded" : "&status=requires_payment_method&status=requires_action&status=stripe_not_connected"}${sortBy ? "&payment_type=" + sortBy : ""}${searchValue ? "&search=" + window.encodeURIComponent(searchValue) : ""}${dateFrom && dateTo ? `&date_from=${dateFrom}&date_to=${dateTo}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getBillingReport(cookies: Cookies, dateFrom?: string, dateTo?: string) {
        return instance.get<unknown, BillingReportAxiosResponse>(
            `/shipments/user/create-billing-report/${dateFrom ? `?date_from=${dateFrom}` : ""}${dateTo ? `&date_to=${dateTo}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    createBillingReportFile(cookies: Cookies, data: CreateBillingReportFileRequestData) {
        return instance.post<unknown, CreateBillingReportFileAxiosResponse>("/shipments/user/create-billing-report/", data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getTenantRatesInfo(cookies: Cookies, tenantId: string) {
        return instance.get<unknown, TGetTenantRatesInfo>(`/payments/tenant-rate-info/${tenantId}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getUserPaymentsDue(cookies: Cookies) {
        return instance.get<TUserPaymentsDue>(`payments/user-payment-due/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getUserPaymentsOverdue(cookies: Cookies) {
        return instance.get<TUserPaymentsDue>(`payments/user-payment-overdue/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postChargeForPaymentGroup(cookies: Cookies, data: TPostChargeForPaymentGroup) {
        return instance.post(`payments/charge-for-payment-group/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
