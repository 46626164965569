import ReCAPTCHA from "react-google-recaptcha";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Container, Button, Form, Row } from "react-bootstrap";

import { onLogin } from "../../../redux/authReducer";
import { login } from "../SignUp/validation/validation";
import { useAppDispatch, useAppSelector } from "../../../redux/ReduxStore";

import preloader from "../../../assets/video/preloader.webp";
import skudropLogo from "../../../assets/img/skudrop-logo.png";

export type SignInSubmitDataType = {
    email: string;
    password: string;
};

const SignIn = () => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const isLoading = useAppSelector((state) => state.auth.isLoading);
    const loginError = useAppSelector((state) => state.auth.registerError);
    const cookies = useAppSelector((state) => state.auth.cookies);

    const [isEnabledCaptcha] = useState(process.env.REACT_APP_LOGIN_CAPTCHA_ENABLED === "true" && process.env.NODE_ENV !== "development");
    const [isValidCaptcha, setIsValidCaptcha] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SignInSubmitDataType>({
        resolver: yupResolver(login),
    });

    useEffect(() => {
        if (loginError === "Register tenant needed") {
            navigate("/register");
        }
        setIsValidCaptcha(false);
    }, [setIsValidCaptcha, loginError]);

    const onSubmit = (data: SignInSubmitDataType) => {
        dispatch(onLogin(data.email.toLowerCase(), data.password, cookies));
    };

    const onHaveAccount = () => {
        navigate("/register");
    };

    const onForgotPassword = () => {
        navigate("/reset-password");
    };

    if (isLoading) {
        return (
            <Container className="block auth-form-container">
                <Form className="auth-form d-flex align-items-center justify-content-center shadow-sm">
                    <img src={preloader} alt="Loading..." style={{ marginLeft: 40 }} width="150" height="150"></img>
                </Form>
            </Container>
        );
    }
    return (
        <Container className="block auth-form-container">
            <Form className="auth-form shadow-sm" onSubmit={handleSubmit(onSubmit)} noValidate data-testid="section_Log In to SKUdrop">
                <Row className="d-flex justify-content-center">
                    <img src={skudropLogo} className="skudrop-logo my-4" alt="logo" />
                </Row>
                <Row className="mb-2 mt-3">
                    <Col>
                        <h4 aria-label="login-page-title">Log In to SKUdrop</h4>
                    </Col>
                </Row>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Control className={errors.email ? "p-2 input" : "p-2"} type="email" placeholder="Enter email" {...register("email")} data-testid="input_Email" />
                    {errors.email && <p className="mb-0 error-message">{errors.email.message}</p>}
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Control className={errors.password ? "p-2 input" : "p-2"} type="password" placeholder="Password" {...register("password")} data-testid="input_Password" />
                    {(errors.password || loginError) && (
                        <div className="error-message">
                            {errors.password && <p className="mb-0">{errors.password.message}</p>}
                            {loginError === 401 && <p className="mb-0">Invalid password or email</p>}
                        </div>
                    )}
                </Form.Group>
                {isEnabledCaptcha ? (
                    <>
                        <Form.Group className={`d-flex justify-content-center mb-4 ${errors.password && loginError ? "mt-3" : ""}`}>
                            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""} onChange={(e) => setIsValidCaptcha(!!e)} />
                        </Form.Group>
                        <Button name="login-page_login-button" variant="primary" type="submit" disabled={!isValidCaptcha} data-testid="btn_LOG IN">
                            Log In
                        </Button>
                    </>
                ) : (
                    <Button className={errors.password && loginError ? "mt-3" : ""} name="login-page_login-button" variant="primary" type="submit" data-testid="btn_LOG IN">
                        Log In
                    </Button>
                )}
                <Row className="mb-0 mt-4">
                    <Col>
                        <span className="d-flex justify-content-center">
                            Don't have an account?{" "}
                            <span className="have-account" onClick={onHaveAccount}>
                                Sign Up
                            </span>
                        </span>
                    </Col>
                </Row>
                <Row className="mb-0 mt-1">
                    <Col>
                        <span className="d-flex justify-content-center">
                            Forgot password?{" "}
                            <span data-testid="btn_Reset Password" className="have-account" onClick={onForgotPassword}>
                                Reset Password
                            </span>
                        </span>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default SignIn;
