import axios from "axios";
import Cookies from "universal-cookie";
import {
    TGetGeocodedAddress,
    TGetParcelsappTrackingInfo,
    TGetShipmentAddressReq,
    TGoogleWayResp,
    TShipmentTrackingPosition,
    TTrackingShipmentsResult,
    TUpsTrackingDetails,
} from "../redux/types/TrackingTypes/tracking.types";
import { instance } from "./authAPI";

export const getTrackingContainerInfo = async (shipment: TTrackingShipmentsResult) => {
    try {
        const res = await axios.post<unknown, { data: string }>(`https://shipsgo.com/api/v1.1/ContainerService/PostContainerInfoWithBl`, {
            authCode: process.env.REACT_APP_SHIPGO_API_KEY,
            blContainersRef: shipment.container_number,
            shippingLine: "OTHER",
        });

        return res?.data;
    } catch (e) {
        console.log("error", e);
    }
};

export const getShipmentCurrentTrackingInfo = async (trackingId: string) => {
    try {
        const res = await axios.get<unknown, { data: TShipmentTrackingPosition[] }>(
            `https://shipsgo.com/api/v1.1/ContainerService/GetContainerInfo?authCode=${process.env.REACT_APP_SHIPGO_API_KEY}&requestId=${trackingId}&mapPoint=true`
        );

        return res?.data;
    } catch (e) {
        console.log("error", e);
    }
};

export const getGeocodedAddress = async (address: string) => {
    try {
        const res = await axios.get<unknown, { data: TGetGeocodedAddress }>(
            `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&address=${address}`
        );

        return res?.data;
    } catch (e) {
        console.log("error", e);
    }
};

const getTrackingInfoWithUUID = async (uuid: string, count = 0): Promise<TGetParcelsappTrackingInfo | undefined> => {
    try {
        const res = await axios.get<unknown, { data: TGetParcelsappTrackingInfo }>(
            `https://parcelsapp.com/api/v3/shipments/tracking/?apiKey=${process.env.REACT_APP_PARCELAPP_API_KEY}&uuid=${uuid}`
        );

        if (res.data?.done) {
            return res.data;
        } else if (count < 10) {
            await new Promise((resolve) => setTimeout(resolve, 4000));
            return getTrackingInfoWithUUID(uuid, count + 1);
        }
    } catch (error) {
        console.log(error);
    }
};

export const getParcelAppTrackingInfo = async (trackingId: string) => {
    try {
        const res = await axios.post<unknown, { data: TGetParcelsappTrackingInfo }>(`https://parcelsapp.com/api/v3/shipments/tracking`, {
            apiKey: process.env.REACT_APP_PARCELAPP_API_KEY,
            shipments: [{ trackingId, language: "en", country: "United States" }],
        });

        if (res.data.uuid) {
            const resWithUUID = await getTrackingInfoWithUUID(res.data.uuid);

            return resWithUUID;
        } else {
            return res?.data;
        }
    } catch (e) {
        console.log(e);
    }
};

export const getShipmentAddress = async (cookies: Cookies, tracking_ids: TGetShipmentAddressReq[]) => {
    try {
        const responce = await instance.post<unknown, { data: TUpsTrackingDetails[] }>(
            `/tracking/get_address/`,
            {
                tracking_ids,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );

        return responce?.data;
    } catch (e) {
        console.log(e);
    }
};

export const getGoogleWay = async (cookies: Cookies, origin_point: string, destination_point: string) => {
    try {
        const responce = await instance.post<unknown, { data: TGoogleWayResp }>(
            `/tracking/get_google_way/`,
            {
                origin_point,
                destination_point,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );

        return responce?.data;
    } catch (e) {
        console.log(e);
    }
};
