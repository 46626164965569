import Cookies from "universal-cookie";

import { instance } from "./authAPI";
import { processRequestQueryParams } from "./utils/apiUtils";
import { IntegrationsListType } from "../redux/types/SendToAmazon/sendToAmazon.types";
import { TGenericRequestFormat, TRequestQueryParams } from "../redux/types/common/common.types";
import {
    TLiveQuote,
    TPostBulkUploadFile,
    TPostCreateOffAmazonQuoteRequestData,
    TPostCreateToAmazonQuoteRequestData,
    TQuoteBulkUploadFileCarton,
    TRequestQuoteAmazonProduct,
} from "../redux/types/manualQuotingTypes/manualQuotingTypes";

export const manualQuotingTenantApi = {
    getLiveQuotes(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericRequestFormat<TLiveQuote>>(`/manual_quoting/quote-shipment/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getQuoteAvailableIntegrations(cookies: Cookies) {
        return instance.get<TGenericRequestFormat<IntegrationsListType>>(`/manual_quoting/integrations/available/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getRequestQuoteProducts(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericRequestFormat<TRequestQuoteAmazonProduct>>(`/manual_quoting/quote-shipment/cartons/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postCreateQuoteRequest(cookies: Cookies, reqData: TPostCreateToAmazonQuoteRequestData | TPostCreateOffAmazonQuoteRequestData) {
        return instance.post(`/manual_quoting/quote-shipment-create/`, reqData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postUploadToAmazonLabels(cookies: Cookies, formData: FormData) {
        return instance.post(`/manual_quoting/labels-to-amazon/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postUploadOffAmazonLabels(cookies: Cookies, formData: FormData) {
        return instance.post(`/manual_quoting/labels-off-amazon/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchReviewLiveQuote(cookies: Cookies, id: string, data: { accepted_by_user?: true; rejected_by_user?: true }) {
        return instance.patch(`/manual_quoting/quote-shipment/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postQuoteBulkDownloadFile(cookies: Cookies, reqData: TPostBulkUploadFile) {
        return instance.post(`/manual_quoting/quote-orders-file-generation/`, reqData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postQuoteBulkUploadFile(cookies: Cookies, formData: FormData) {
        return instance.post<TQuoteBulkUploadFileCarton[]>(`/manual_quoting/quote-orders-file/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
