import Cookies from "universal-cookie";

import {
    AxiosResponseIntegrationListType,
    CreateShipmentPlanRequestType,
    GetAmazonProductRootObject,
    SubmitShipmentRequestType,
    TGetFFDetailsResp,
    TGetShipmentPlanResponse,
    TOwnFFAwdPlanItem,
    TOwnFFPlanItem,
    TPostCreateFlatFileData,
    TPostCreateOwnFFPlanData,
} from "./../redux/types/SendToAmazon/sendToAmazon.types";
import { instance } from "./authAPI";
import { TGenericRequestFormat } from "../redux/types/common/common.types";

export const sendToAmazon = {
    getAllIntegrations(cookies: Cookies) {
        return instance.get<AxiosResponseIntegrationListType>(`/amazon_mws/integrations/available/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAmazonProduct(cookies: Cookies, integration_id: string, searchTerm: string, items: number, offset: number) {
        return instance.get<GetAmazonProductRootObject>(
            `/amazon_mws/shipment-plan/?integration_id=${integration_id}&limit=${items}&offset=${offset}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    createShipmentPlan(cookies: Cookies, data: CreateShipmentPlanRequestType[], integrationId: string) {
        return instance.post(
            `/amazon_mws/shipment-plan/`,
            {
                cartons: data,
                integration_id: integrationId,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getShipmentPlan(cookies: Cookies, integration_id: string) {
        return instance.get<TGetShipmentPlanResponse>(`/amazon_mws/inbound-shipment/?integration_id=${integration_id}&limit=1444`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteShipmentPlan(selected: string[], cookies: Cookies) {
        return instance.delete(`/amazon_mws/shipment-plan/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
            data: {
                ids: selected,
            },
        });
    },
    submitShipment(cookies: Cookies, data: { shipments: SubmitShipmentRequestType[]; integration_id: string; use_credits: boolean }) {
        return instance.post(`/amazon_mws/inbound-shipment/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    consolidateShipmentPlans(cookies: Cookies, creation_group_id: string, integration_id: string) {
        return instance.post(
            `/amazon_mws/shipment-plan/`,
            {
                creation_group_id,
                integration_id,
                multiple_sku_enabled: true,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    postCreateOwnFFShipmentPlan(cookies: Cookies, formData: FormData) {
        return instance.post(`/shipments/own-ff-shipment/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getFFDetails(cookies: Cookies) {
        return instance.get<TGetFFDetailsResp>(`/shipments/ff_details/?limit=100`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    createFlatFile(cookies: Cookies, data: TPostCreateFlatFileData) {
        return instance.post<{ url: string }>(`/shipments/flat_file/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOwnFFPlans(cookies: Cookies, integrationId: string) {
        return instance.get<TGenericRequestFormat<TOwnFFPlanItem>>(`/shipments/own_ff_plans/?integration_id=${integrationId}&limit=1000`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postCreateOwnFFPlan(cookies: Cookies, data: TPostCreateOwnFFPlanData) {
        return instance.post<TPostCreateOwnFFPlanData>(`/shipments/own_ff_plans/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteOwnFFPlans(cookies: Cookies, ids: string[]) {
        return instance.delete<string>(`/shipments/own_ff_plans/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
            data: { ids },
        });
    },
    postOwnFFPlanDetails(cookies: Cookies, shipment_id: string) {
        return instance.post<string>(
            `/shipments/own_ff_plan_details/`,
            { shipment_id },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    postProcessOwnFFToAmazonLabels(cookies: Cookies, data: FormData) {
        return instance.post<string>(`/shipments/own_ff_plan_file_details/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Own FF AWD shipments
    getOwnFFAwdPlans(cookies: Cookies, integrationId: string) {
        return instance.get<TGenericRequestFormat<TOwnFFAwdPlanItem>>(`/shipments/own_ff_plans_awd/?integration_id=${integrationId}&limit=1000`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteOwnFFAwdPlans(cookies: Cookies, idsToDelete: string[]) {
        return instance.delete<string>(`/shipments/own_ff_plans_awd/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
            data: { ids: idsToDelete },
        });
    },
    postProcessOwnFFToAmazonAwdLabels(cookies: Cookies, formData: FormData) {
        return instance.post(`/shipments/own_ff_plans_awd/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
