import { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Button, Form, Row, Col } from "react-bootstrap";

import { schemaSecondStep } from "./validation/validation";
import { registerSecondStep } from "../../../redux/authReducer";
import { useAppDispatch, useAppSelector } from "../../../redux/ReduxStore";

import preloader from "../../../assets/video/preloader.webp";

type SignUpSecondStepPropsType = {
    logo: string;
};

type SecondStepSubmitDataType = {
    password: string;
    confirmPassword: string | undefined;
};

const SignUpSecondStep: FC<SignUpSecondStepPropsType> = ({ logo }) => {
    const dispatch = useAppDispatch();
    const cookies = useAppSelector((state) => state.auth.cookies);
    const isLoading = useAppSelector((state) => state.auth.isLoading);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SecondStepSubmitDataType>({
        resolver: yupResolver(schemaSecondStep),
    });

    const onSubmit = (data: SecondStepSubmitDataType) => {
        dispatch(registerSecondStep(cookies.get("confirmId"), cookies.get("confirmToken"), data.password, cookies));
    };

    if (isLoading) {
        return (
            <Container className="block auth-form-container">
                <Form className="auth-form d-flex align-items-center justify-content-center shadow-sm">
                    <img src={preloader} style={{ marginLeft: 40 }} width="150" height="150" alt="Loading..."></img>
                </Form>
            </Container>
        );
    }

    return (
        <Container className="block auth-form-container container-bg">
            <Form className="auth-form shadow-sm" onSubmit={handleSubmit(onSubmit)} noValidate data-testid="section_Create password">
                <Row className="d-flex justify-content-center">
                    <img src={logo} className="skudrop-logo my-4" alt="logo" />
                </Row>
                <Row className="mb-2 mt-3">
                    <Col>
                        <h4>Create password</h4>
                    </Col>
                </Row>
                <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Control className={errors.password ? "p-2 input" : "p-2"} type="password" placeholder="Password" {...register("password")} data-testid="input_Password" />
                    {errors.password && <p className="mb-0 error-message">{errors.password.message}</p>}
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicConfirmPassword">
                    <Form.Control
                        className={errors.password ? "p-2 input" : "p-2"}
                        type="password"
                        placeholder="Confirm Password"
                        {...register("confirmPassword")}
                        data-testid="input_Confirm Password"
                    />
                    {errors.confirmPassword && <p className="mb-0 error-message">{errors.confirmPassword.message}</p>}
                </Form.Group>
                <Button variant="primary" type="submit" data-testid="btn_CREATE ACCOUNT">
                    CREATE ACCOUNT
                </Button>
            </Form>
        </Container>
    );
};

export default SignUpSecondStep;
