import Cookies from "universal-cookie";

import {
    TRatesTenantList,
    TPatchPersonalRatesData,
    TPatchUpdateTenantPersonalTiersReqData,
    TTenantPersonalTiers,
    TGetTenantPersonalRates,
    TPatchTenantPaymentsTermsData,
    TManagementRatesObject,
    TPatchManagementRates,
    TPatchManagementRatesResponse,
} from "./../redux/types/ChargingRatesTypes/ChargingRates.types";
import { instance } from "./authAPI";
import { UpdateChargingRateObject } from "../redux/types/ChargingRatesTypes/ChargingRates.types";

export const ratesProductAPI = {
    getRatesProduct(cookies: Cookies) {
        return instance.get(`/rates/actions/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postRatesProductFile(cookies: Cookies, formData: FormData) {
        return instance.post(`/rates/files-upload/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
                "Content-Type": "multipart/form-data",
            },
        });
    },
    postRatesProduct(cookies: Cookies, data: UpdateChargingRateObject) {
        return instance.post(`/rates/actions/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Customer rates
    getTenantPersonalRates(cookies: Cookies, tenantId: string) {
        return instance.get<TGetTenantPersonalRates>(`/rates/personal-rates/?tenant__id=${tenantId}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchTenantPersonalRates(cookies: Cookies, ratesId: string, data: TPatchPersonalRatesData) {
        return instance.patch(`/rates/personal-rates/${ratesId}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getTenantsList(cookies: Cookies) {
        return instance.get<TRatesTenantList[]>(`/rates/tenant_list/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Customer tiers
    getTenantPersonalTiers(cookies: Cookies, tenantId: string) {
        return instance.get<TTenantPersonalTiers>(`/rates/custom-tiers/?tenant_id=${tenantId}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchUpdateTenantPersonalTiers(cookies: Cookies, tiersId: string, data: TPatchUpdateTenantPersonalTiersReqData) {
        return instance.patch(`/rates/custom-tiers/${tiersId}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchTenantPaymentsTerms(cookies: Cookies, ratesId: string, data: TPatchTenantPaymentsTermsData) {
        return instance.patch(`/rates/personal-rates/${ratesId}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getRatesManagement(cookies: Cookies) {
        return instance.get<TManagementRatesObject>(`/rates/rates-management/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchRatesManagement(cookies: Cookies, id: string, data: TPatchManagementRates) {
        return instance.patch<TPatchManagementRatesResponse>(`/rates/rates-management/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
