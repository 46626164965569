import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Container, Button, Form, Row } from "react-bootstrap";

import { resetPassword } from "../../../redux/authReducer";
import { schemaSecondStep } from "../SignUp/validation/validation";
import { useAppDispatch, useAppSelector } from "../../../redux/ReduxStore";

import preloader from "../../../assets/video/preloader.webp";
import skudropLogo from "../../../assets/img/skudrop-logo.png";

export type ResetPasswordPropsType = {
    id: string;
    token: string;
};

export type ResetPasswordSubmitData = {
    password: string;
    confirmPassword: string | undefined;
};

const ResetPassword: FC<ResetPasswordPropsType> = ({ id, token }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isLoading = useAppSelector((state) => state.auth.isLoading);
    const loginError = useAppSelector((state) => state.auth.registerError);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ResetPasswordSubmitData>({
        resolver: yupResolver(schemaSecondStep),
    });

    const redirectToLogin = () => {
        navigate("/login");
    };

    const onSubmit = (data: ResetPasswordSubmitData) => {
        console.log(data);
        dispatch(resetPassword(id, token, data.password, redirectToLogin));
    };

    if (isLoading) {
        return (
            <Container className="block auth-form-container">
                <Form className="auth-form d-flex align-items-center justify-content-center shadow-sm">
                    <img src={preloader} alt="Loading..." style={{ marginLeft: 40 }} width="150" height="150"></img>
                </Form>
            </Container>
        );
    }
    return (
        <Container className="block auth-form-container">
            <Form className="auth-form shadow-sm" onSubmit={handleSubmit(onSubmit)} noValidate data-testid="section_Reset Password">
                <Row className="d-flex justify-content-center">
                    <img src={skudropLogo} className="skudrop-logo my-4" alt="logo" />
                </Row>
                <Row className="mb-2 mt-3">
                    <Col>
                        <h4>Reset Password</h4>
                    </Col>
                </Row>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Control
                        className={errors.password ? "p-2 input" : "p-2"}
                        type="password"
                        placeholder="New password"
                        {...register("password")}
                        data-testid="input_New password"
                    />
                    {errors.password && <p className="mb-0 error-message">{errors.password.message}</p>}
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Control
                        className={errors.password ? "p-2 input" : "p-2"}
                        type="password"
                        placeholder="Confirm new password"
                        {...register("confirmPassword")}
                        data-testid="input_Confirm new password"
                    />
                    {errors.password && <p className="mb-0 error-message">{errors.password.message}</p>}
                    {loginError === 401 && <p className="mb-0 error-message">Invalid password or email</p>}
                </Form.Group>
                <Button variant="primary" type="submit" data-testid="btn_SAVE">
                    Save
                </Button>
            </Form>
        </Container>
    );
};

export default ResetPassword;
