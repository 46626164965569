import axios from "axios";

import { displayError } from "../hooks/useErrorHandler";
import { manualQuotingTenantApi } from "../api/manualQuotingApi";
import { TRequestLazySearchParams } from "./types/common/common.types";
import { IntegrationsListType } from "./types/SendToAmazon/sendToAmazon.types";
import {
    ManualQuotingThunkType,
    TLiveQuote,
    TManualQuotingActions,
    TManualQuotingLoadingStates,
    TPostBulkUploadFile,
    TPostCreateOffAmazonQuoteRequestData,
    TPostCreateToAmazonQuoteRequestData,
    TQuoteBulkUploadFileCarton,
    TRequestQuoteAmazonProduct,
    TRequestQuoteProductsState,
    TSetLiveQuotes,
    TSetManualQuotingLoadingStates,
    TSetManualQuotingLoadingStatesData,
    TSetQuoteIntegrations,
    TSetQuoteUploadFile,
    TSetRequestQuoteProducts,
    TSetRequestQuoteProductsData,
} from "./types/manualQuotingTypes/manualQuotingTypes";

export const SET_QUOTE_INTEGRATIONS = "SET_QUOTE_INTEGRATIONS";
export const SET_REQUEST_QUOTE_PRODUCTS = "SET_REQUEST_QUOTE_PRODUCTS";
export const SET_LIVE_QUOTES = "SET_LIVE_QUOTES";
export const SET_QUOTING_LOADING_STATES = "SET_QUOTING_LOADING_STATES";
export const SET_BULK_UPLOAD_FILE = "SET_BULK_UPLOAD_FILE";

export type InitialStateType = {
    liveQuotes: TLiveQuote[] | null;
    quoteIntegrations: IntegrationsListType[] | null;
    requestQuoteProducts: TRequestQuoteProductsState;
    quoteBulkUploadFile: string | null;
    loadingStates: TManualQuotingLoadingStates;
};

let initialState: InitialStateType = {
    liveQuotes: null,
    quoteIntegrations: null,
    requestQuoteProducts: {
        toAmazon: null,
        offAmazon: null,
    },
    quoteBulkUploadFile: null,
    loadingStates: {
        isLoadingLiveQuotes: false,
        isLoadingQuoteReview: false,
        isLoadingRequestQuote: false,
        isLoadingQuoteIntegrations: false,
        isLoadingBulkUpload: false,
    },
};

const manualQuotingReducer = (state = initialState, action: TManualQuotingActions): InitialStateType => {
    switch (action.type) {
        case SET_LIVE_QUOTES: {
            return {
                ...state,
                liveQuotes: action.data,
            };
        }
        case SET_QUOTE_INTEGRATIONS: {
            return {
                ...state,
                quoteIntegrations: action.data,
            };
        }
        case SET_REQUEST_QUOTE_PRODUCTS: {
            return {
                ...state,
                requestQuoteProducts: { ...state.requestQuoteProducts, ...action.data },
            };
        }
        case SET_BULK_UPLOAD_FILE: {
            return {
                ...state,
                quoteBulkUploadFile: action.data,
            };
        }
        case SET_QUOTING_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        default:
            return state;
    }
};

// Action Creators

export const SetLiveQuotes = (data: TLiveQuote[]): TSetLiveQuotes => ({
    type: SET_LIVE_QUOTES,
    data,
});

export const SetQuoteIntegrations = (data: IntegrationsListType[]): TSetQuoteIntegrations => ({
    type: SET_QUOTE_INTEGRATIONS,
    data,
});

export const SetRequestQuoteProducts = (data: TSetRequestQuoteProductsData): TSetRequestQuoteProducts => ({
    type: SET_REQUEST_QUOTE_PRODUCTS,
    data,
});

export const SetQuoteUploadFile = (data: string): TSetQuoteUploadFile => ({
    type: SET_BULK_UPLOAD_FILE,
    data,
});

export const SetQuotingLoadingStates = (data: TSetManualQuotingLoadingStatesData): TSetManualQuotingLoadingStates => ({
    type: SET_QUOTING_LOADING_STATES,
    data,
});

// Thunks

export const getLiveQuotes = (queryParams: TRequestLazySearchParams, setProductsAllNext: (data: boolean) => void): ManualQuotingThunkType => {
    return async (dispatch, getState) => {
        try {
            if (!queryParams.offset) {
                dispatch(SetQuotingLoadingStates({ isLoadingLiveQuotes: true }));
            }

            const cookies = getState().auth.cookies;

            const response = await manualQuotingTenantApi.getLiveQuotes(cookies, queryParams);

            setProductsAllNext(!!response.data.next);

            dispatch(SetLiveQuotes(response.data.results));

            if (!queryParams.offset) {
                dispatch(SetQuotingLoadingStates({ isLoadingLiveQuotes: false }));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchReviewLiveQuote = (
    id: string,
    data: { accepted_by_user?: true; rejected_by_user?: true; shipping_labels_required?: boolean },
    onSuccess: () => void,
    triggerInitialLiveQuotesRequest: () => void
): ManualQuotingThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetQuotingLoadingStates({ isLoadingQuoteReview: true }));

            const cookies = getState().auth.cookies;

            await manualQuotingTenantApi.patchReviewLiveQuote(cookies, id, data);

            onSuccess();

            triggerInitialLiveQuotesRequest();

            dispatch(SetQuotingLoadingStates({ isLoadingQuoteReview: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetQuotingLoadingStates({ isLoadingQuoteReview: false }));

                displayError(e.response.data);
            }
        }
    };
};

export const getQuoteIntegrations = (): ManualQuotingThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetQuotingLoadingStates({ isLoadingQuoteIntegrations: true }));

            const cookies = getState().auth.cookies;

            const response = await manualQuotingTenantApi.getQuoteAvailableIntegrations(cookies);

            dispatch(SetQuoteIntegrations(response.data.results));

            dispatch(SetQuotingLoadingStates({ isLoadingQuoteIntegrations: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getRequestQuoteProducts = (
    type: "toAmazon" | "offAmazon",
    queryParams: TRequestLazySearchParams & Partial<{ integration_id: string | null }>,
    setProductsAllNext: (data: boolean) => void
): ManualQuotingThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            const response = await manualQuotingTenantApi.getRequestQuoteProducts(cookies, queryParams);

            setProductsAllNext(!!response.data.next);

            dispatch(SetRequestQuoteProducts({ [type]: response.data.results }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postCreateOffAmazonQuoteRequest = (
    reqData: TPostCreateOffAmazonQuoteRequestData,
    onSuccess: () => void,
    triggerInitialLiveQuotesRequest: () => void
): ManualQuotingThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetQuotingLoadingStates({ isLoadingRequestQuote: true }));

            const cookies = getState().auth.cookies;

            await manualQuotingTenantApi.postCreateQuoteRequest(cookies, reqData);

            onSuccess();

            triggerInitialLiveQuotesRequest();

            dispatch(SetQuotingLoadingStates({ isLoadingRequestQuote: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetQuotingLoadingStates({ isLoadingRequestQuote: false }));

                displayError(e.response.data);
            }
        }
    };
};

export const postCreateToAmazonQuoteRequest = (
    reqData: TPostCreateToAmazonQuoteRequestData,
    onSuccess: () => void,
    triggerInitialLiveQuotesRequest: () => void
): ManualQuotingThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetQuotingLoadingStates({ isLoadingRequestQuote: true }));

            const cookies = getState().auth.cookies;

            await manualQuotingTenantApi.postCreateQuoteRequest(cookies, reqData);

            onSuccess();

            triggerInitialLiveQuotesRequest();

            dispatch(SetQuotingLoadingStates({ isLoadingRequestQuote: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetQuotingLoadingStates({ isLoadingRequestQuote: false }));

                displayError(e.response.data);
            }
        }
    };
};

// Bulk Upload

export const postQuoteBulkDownloadFile = (reqData: TPostBulkUploadFile): ManualQuotingThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetQuotingLoadingStates({ isLoadingBulkUpload: true }));

            const cookies = getState().auth.cookies;

            const response = await manualQuotingTenantApi.postQuoteBulkDownloadFile(cookies, reqData);

            dispatch(SetQuoteUploadFile(response.data));

            dispatch(SetQuotingLoadingStates({ isLoadingBulkUpload: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetQuotingLoadingStates({ isLoadingBulkUpload: false }));

                displayError(e.response.data);
            }
        }
    };
};

export const postQuoteBulkUploadFile = (
    formData: FormData,
    setProductAllNext: (value: boolean) => void,
    toAmazonTriggerInitialState: () => void,
    handleProcessToAmazonBulkUpload: (fileData: TQuoteBulkUploadFileCarton[], products: TRequestQuoteAmazonProduct[]) => void
): ManualQuotingThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetQuotingLoadingStates({ isLoadingRequestQuote: true }));

            const cookies = getState().auth.cookies;

            const response1 = await manualQuotingTenantApi.postQuoteBulkUploadFile(cookies, formData);

            toAmazonTriggerInitialState();

            const response2 = await manualQuotingTenantApi.getRequestQuoteProducts(cookies, { search: "", limit: 20, offset: 0 });

            const productsToReceive = response1.data
                .filter((item) => !response2.data.results.some((obj) => obj.sku === item.SKU))
                .map((item) => item.SKU)
                .join(",");

            let response3;

            if (productsToReceive) {
                response3 = await manualQuotingTenantApi.getRequestQuoteProducts(cookies, { search: productsToReceive, items: 1000, offset: 0 });
            }

            const products = [...(response3?.data?.results || []), ...response2.data.results];

            handleProcessToAmazonBulkUpload(response1.data, products);

            dispatch(SetRequestQuoteProducts({ toAmazon: products }));

            setProductAllNext(!!response2.data.next);

            dispatch(SetQuotingLoadingStates({ isLoadingRequestQuote: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetQuotingLoadingStates({ isLoadingRequestQuote: false }));

                dispatch(getRequestQuoteProducts("toAmazon", { search: "", limit: 20, offset: 0 }, setProductAllNext));

                displayError(e.response.data);
            }
        }
    };
};

export default manualQuotingReducer;
