import Cookies from "universal-cookie";
import { UserIntegrationAxiosResponse } from "../redux/types/SettingsTypes/settings.types";
import { instance } from "./authAPI";

export const userIntegration = {
    patchIntegrationName(cookies: Cookies, integration_name: string, integration_id: string) {
        return instance.patch(
            `integrations/actions-integration/${integration_id}/`,
            { name: integration_name },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getUserIntegration(cookies: Cookies) {
        return instance.get<unknown, UserIntegrationAxiosResponse>(`/integrations/actions-integration/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    actionIntegration(cookies: Cookies, id: string, status: string) {
        return instance.patch(
            `/integrations/actions-integration/${id}/`,
            { status: status },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};
