import Cookies from "universal-cookie";
import { PeriodDetailFilterType } from "../components/DashboardPortal/AirShipping/AirShippingTab";
import { generateRangeDate } from "../components/DashboardPortal/helpers/helpers";
import { TDownloadStatisticFileData } from "../components/DashboardWarehouse/Statistics/StatisticsInfo/StatisticsInfo";
import { GetStatisticsChartsAxiosResponseType, GetStatisticsInfoAxiosResponseType, TDownloadStatisticFileResponse } from "../redux/types/StatisticsTypes/statistics.types";
import { instance } from "./authAPI";

export const statisticsAPI = {
    getStatisticsInfo(cookies: Cookies, periodFilter: PeriodDetailFilterType) {
        const { generatedStartDate, generatedEndDate } = generateRangeDate(periodFilter);

        return instance.get<void, GetStatisticsInfoAxiosResponseType>(
            `shipments/admin/statistic_area/${generatedStartDate && generatedEndDate ? `?date_from=${generatedStartDate}&date_to=${generatedEndDate}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getStatisticsCharts(cookies: Cookies, periodFilter: PeriodDetailFilterType) {
        const { generatedStartDate, generatedEndDate } = generateRangeDate(periodFilter);

        return instance.get<void, GetStatisticsChartsAxiosResponseType>(
            `shipments/admin/charts/${generatedStartDate && generatedEndDate ? `?date_from=${generatedStartDate}&date_to=${generatedEndDate}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    downloadStatisticsFile(cookies: Cookies, data: TDownloadStatisticFileData) {
        return instance.post<unknown, TDownloadStatisticFileResponse>(`shipments/admin/statisc_area_file/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
