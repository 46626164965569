import { instance } from "./authAPI";
import {
    TGetAvailableTrackingTypes,
    TShipsGoExternalTrackingIds,
    TTrackingShipmentsList,
    TDeliveredToPortObj,
    TActiveTrackingFilter,
} from "../redux/types/TrackingTypes/tracking.types";
import Cookies from "universal-cookie";

export const trackingApi = {
    getContainersTracking(cookies: Cookies, latestShipped: boolean, activeTrackingFilter: TActiveTrackingFilter, items: number, offset: number, searchValue: string) {
        return instance.get<unknown, { data: TTrackingShipmentsList }>(
            `/tracking/containers_tracking/?limit=${items}&offset=${offset}&ordering=${latestShipped ? "arrived_to_amazon_at" : "-created_at"}${activeTrackingFilter.boat_calculation_type ? `&boat_calculation_type=${activeTrackingFilter.boat_calculation_type}` : ""}${activeTrackingFilter.amazon_status ? `&amazon_status=${activeTrackingFilter.amazon_status}` : ""}${searchValue ? `&search=${window.encodeURIComponent(searchValue)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    setContainerTrackingProgram(cookies: Cookies, shipsGoTrackingIds: TShipsGoExternalTrackingIds[], orders_delivered_to_port: TDeliveredToPortObj[]) {
        return instance.post(
            `/tracking/tracking_program/`,
            {
                containers: shipsGoTrackingIds,
                orders_delivered_to_port,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getAvailableTrackingTypes(cookies: Cookies, searchValue: string) {
        return instance.get<unknown, { data: TGetAvailableTrackingTypes }>(
            `/tracking/available_tracking/${searchValue ? `?search=${window.encodeURIComponent(searchValue)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};
