import React from "react";
import { Button } from "react-bootstrap";

export interface InactiveButtonPropsType {
    text: string;
    dataTestid?: string;
    styles: string;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const InactiveButton = ({ text, dataTestid, styles, disabled, onClick }: InactiveButtonPropsType) => {
    return (
        <Button variant="primary" data-testid={dataTestid} disabled={disabled} className={`text-transform-none px-4 line-height-1 ${styles}`} onClick={onClick}>
            {text}
        </Button>
    );
};

export default InactiveButton;
