import Cookies from "universal-cookie";
import { AmazonLinkAxiosResponse, AmazonUrlAxiosResponse } from "../redux/types/AuthTypes/auth.types";
import { instance } from "./authAPI";

export const amazon = {
    getAmazonUrl(confirmId: string, tenant_id: string) {
        return instance.get<unknown, AmazonUrlAxiosResponse>(`/integrations/request/oauth/link/${confirmId}/${tenant_id}`);
    },
    getUrlForIntegration(cookies: Cookies) {
        return instance.get<unknown, AmazonUrlAxiosResponse>(`/integrations/request/integration-link/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    confirmAmazon(spapi_oauth_code: string, state: string, selling_partner_id: string) {
        return instance.post<AmazonLinkAxiosResponse>(
            `integrations/amazon/oauth/redirect/?spapi_oauth_code=${spapi_oauth_code}&state=${state}&selling_partner_id=${selling_partner_id}/`
        );
    },
};
