import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Container, Button, Form, Row } from "react-bootstrap";

import { onSendEmailToReset } from "../../../redux/authReducer";
import { useAppDispatch, useAppSelector } from "../../../redux/ReduxStore";
import { SendEmailToResetValidator } from "../SignUp/validation/validation";

import preloader from "../../../assets/video/preloader.webp";
import skudropLogo from "../../../assets/img/skudrop-logo.png";

export type SendEmailToResetPropsType = {
    setResetStatus: (value: string) => void;
};

export type SendEmailSubmitDataType = {
    email: string;
};

const SendEmailToReset: FC<SendEmailToResetPropsType> = ({ setResetStatus }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isLoading = useAppSelector((state) => state.auth.isLoading);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SendEmailSubmitDataType>({
        resolver: yupResolver(SendEmailToResetValidator),
    });

    const redirectToLogin = () => {
        navigate("/login");
    };

    const onSubmit = (data: SendEmailSubmitDataType) => {
        dispatch(onSendEmailToReset(data.email.toLowerCase(), setResetStatus, redirectToLogin));
    };

    if (isLoading) {
        return (
            <Container className="block auth-form-container">
                <Form className="auth-form d-flex align-items-center justify-content-center shadow-sm">
                    <img src={preloader} alt="Loading..." style={{ marginLeft: 40 }} width="150" height="150"></img>
                </Form>
            </Container>
        );
    }
    return (
        <Container className="block auth-form-container">
            <Form className="auth-form shadow-sm" onSubmit={handleSubmit(onSubmit)} noValidate data-testid="section_Email verification">
                <Row className="d-flex justify-content-center">
                    <img src={skudropLogo} className="skudrop-logo my-4" alt="logo" />
                </Row>
                <Row className="mb-2 mt-3">
                    <Col>
                        <h4>Email verification</h4>
                    </Col>
                </Row>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Control className={errors.email ? "p-2 input" : "p-2"} type="email" placeholder="Enter email" {...register("email")} data-testid="input_Email" />
                    {errors.email && <p className="mb-0 error-message">{errors.email.message}</p>}
                </Form.Group>
                <Button variant="primary" type="submit" data-testid="btn_SEND">
                    Send
                </Button>
            </Form>
        </Container>
    );
};

export default SendEmailToReset;
