import axios from "axios";
import Cookies from "universal-cookie";

import {
    AmazonProduct,
    CreateShipmentPlanRequestType,
    GetShipmentPlan,
    GetShipmentPlanResult,
    IntegrationsListType,
    SelectBoatType,
    SendToAmazonActionTypes,
    SendToAmazonThunkType,
    SetCalculateShipmentPlanType,
    SetErrorAmazonProductType,
    SetIntegrationsType,
    SetLoadingMessageType,
    SetReadyToAmazonProductType,
    SetShipmentPlanType,
    SubmitShipmentRequestType,
    SetLoadingIntegrationsType,
    TSetLoadingShipmentPlans,
    SetReadyToAmazonFFProductType,
    TStateSendToAmazonProducts,
    TSetLoadingFFDetails,
    TSetFFDetails,
    TFFDetailsResults,
    TStateOwnFFSendToAmazonProducts,
    TOwnFFSendToAmazonComponents,
    TPostCreateFlatFileData,
    TSetOwnFFPlans,
    TStateOwnFFPlans,
    TOwnFFPlanItem,
    TPostCreateOwnFFPlanData,
    TShipmentPlanRootObject,
    TStateOwnFFAwdPlans,
    TSetOwnFFAwdPlans,
    TOwnFFAwdPlanItem,
} from "./types/SendToAmazon/sendToAmazon.types";
import { getUser } from "./authReducer";
import { sendToAmazon } from "../api/sendToAmazonAPI";
import { SetLoadingType } from "./types/AuthTypes/auth.types";
import { sortIntegrationList } from "../components/common/utils";
import { displayError, displaySuccess } from "../hooks/useErrorHandler";

export const SET_READY_TO_AMAZON_PRODUCT = "SET_READY_TO_AMAZON_PRODUCT";
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR_AMAZON_PRODUCT = "SET_ERROR_AMAZON_PRODUCT";
export const SET_SHIPMENT_PLAN = "SET_SHIPMENT_PLAN";
export const CALCULATE_SHIPMENT_PLAN = "CALCULATE_SHIPMENT_PLAN";
export const SET_LOADING_MESSAGE = "SET_LOADING_MESSAGE";
export const SET_AMAZON_INTEGRATIONS_LIST = "SET_AMAZON_INTEGRATIONS_LIST";
export const SET_LOADING_SHIPPING_PLANS = "SET_LOADING_SHIPPING_PLANS";
export const SET_LOADING_INTEGRATION = "SET_LOADING_INTEGRATION";
export const SET_LOADING_SHIPMENT_PLANS = "SET_LOADING_SHIPMENT_PLANS";
export const SET_READY_TO_AMAZON_FF_PRODUCT = "SET_READY_TO_AMAZON_FF_PRODUCT";
export const SET_FF_DETAILS = "SET_FF_DETAILS";
export const SET_LOADING_FF_DETAILS = "SET_LOADING_FF_DETAILS";
export const SET_OWN_FF_PLANS = "SET_OWN_FF_PLANS";
export const SET_OWN_FF_AWD_PLANS = "SET_OWN_FF_AWD_PLANS";

type InitalStateType = {
    sendToAmazonProduct: TStateSendToAmazonProducts | null;
    isLoading: boolean;
    isLoadingMessage: null | string;
    isLoadingIntegrations: boolean;
    isLoadingShipmentPlans: boolean;
    isErrorAmazonProduct: null | boolean | string;
    shipmentPlan: null | GetShipmentPlan[];
    calculateShipmentPlan: null | string;
    amazonIntegarationList: null | IntegrationsListType[];
    sendToAmazonFFProducts: TStateOwnFFSendToAmazonProducts | null;
    ffDetails: TFFDetailsResults[] | null;
    isLoadingFFDetails: boolean;
    own_ff_plans: TStateOwnFFPlans | null;
    own_ff_awd_plans: TStateOwnFFAwdPlans | null;
};

let initialState: InitalStateType = {
    sendToAmazonProduct: null,
    isLoading: false,
    isLoadingMessage: null,
    isLoadingIntegrations: false,
    isLoadingShipmentPlans: false,
    isErrorAmazonProduct: null,
    shipmentPlan: null,
    calculateShipmentPlan: null,
    amazonIntegarationList: null,
    sendToAmazonFFProducts: null,
    ffDetails: null,
    isLoadingFFDetails: false,
    own_ff_plans: null,
    own_ff_awd_plans: null,
};

const sendToAmazonReducer = (state = initialState, action: SendToAmazonActionTypes): InitalStateType => {
    switch (action.type) {
        case SET_AMAZON_INTEGRATIONS_LIST: {
            return {
                ...state,
                amazonIntegarationList: action.data,
            };
        }
        case SET_READY_TO_AMAZON_PRODUCT: {
            return {
                ...state,
                sendToAmazonProduct: { ...state?.sendToAmazonProduct, [action.integrationId]: action.data },
            };
        }
        case SET_SHIPMENT_PLAN: {
            return {
                ...state,
                shipmentPlan: action.data,
            };
        }
        case CALCULATE_SHIPMENT_PLAN: {
            return {
                ...state,
                calculateShipmentPlan: action.data,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_LOADING_MESSAGE: {
            return {
                ...state,
                isLoadingMessage: action.data,
            };
        }
        case SET_ERROR_AMAZON_PRODUCT: {
            return {
                ...state,
                isErrorAmazonProduct: action.data,
            };
        }
        case SET_LOADING_INTEGRATION: {
            return {
                ...state,
                isLoadingIntegrations: action.data,
            };
        }
        case SET_LOADING_SHIPMENT_PLANS: {
            return {
                ...state,
                isLoadingShipmentPlans: action.data,
            };
        }
        case SET_READY_TO_AMAZON_FF_PRODUCT: {
            return {
                ...state,
                sendToAmazonFFProducts: {
                    ...state?.sendToAmazonFFProducts,
                    [action.component]: { ...state?.sendToAmazonFFProducts?.[action.component], [action.integrationId]: action.data },
                },
            };
        }
        case SET_FF_DETAILS: {
            return {
                ...state,
                ffDetails: action.data,
            };
        }
        case SET_LOADING_FF_DETAILS: {
            return {
                ...state,
                isLoadingFFDetails: action.data,
            };
        }
        case SET_OWN_FF_PLANS: {
            return {
                ...state,
                own_ff_plans: { ...state?.own_ff_plans, [action.integrationId]: action.data },
            };
        }
        case SET_OWN_FF_AWD_PLANS: {
            return {
                ...state,
                own_ff_awd_plans: { ...state?.own_ff_awd_plans, [action.integrationId]: action.data },
            };
        }
        default:
            return state;
    }
};

export const SetAllIntegrations = (data: IntegrationsListType[]): SetIntegrationsType => ({
    type: SET_AMAZON_INTEGRATIONS_LIST,
    data,
});
export const SetReadyToAmazonProduct = (integrationId: string, data: AmazonProduct[]): SetReadyToAmazonProductType => ({
    type: SET_READY_TO_AMAZON_PRODUCT,
    integrationId,
    data,
});
export const SetReadyToAmazonFFProduct = (component: TOwnFFSendToAmazonComponents, integrationId: string, data: AmazonProduct[]): SetReadyToAmazonFFProductType => ({
    type: SET_READY_TO_AMAZON_FF_PRODUCT,
    component,
    integrationId,
    data,
});
export const SetShipmentPlan = (data: GetShipmentPlan[]): SetShipmentPlanType => ({
    type: SET_SHIPMENT_PLAN,
    data,
});
export const SetCalculateShipmentPlan = (data: null | string): SetCalculateShipmentPlanType => ({
    type: CALCULATE_SHIPMENT_PLAN,
    data,
});
export const SetLoading = (loading: boolean): SetLoadingType => ({
    type: SET_LOADING,
    data: loading,
});
export const SetLoadingIntegrations = (loading: boolean): SetLoadingIntegrationsType => ({
    type: SET_LOADING_INTEGRATION,
    data: loading,
});
export const SetLoadingMessage = (message: null | string): SetLoadingMessageType => ({
    type: SET_LOADING_MESSAGE,
    data: message,
});
export const SetErrorAmazonProduct = (error: null | boolean | string): SetErrorAmazonProductType => ({
    type: SET_ERROR_AMAZON_PRODUCT,
    data: error,
});
export const SetLoadingShipmentPlans = (loading: boolean): TSetLoadingShipmentPlans => ({
    type: SET_LOADING_SHIPMENT_PLANS,
    data: loading,
});
export const SetLoadingFFDetails = (loading: boolean): TSetLoadingFFDetails => ({
    type: SET_LOADING_FF_DETAILS,
    data: loading,
});
export const SetFFDetails = (data: TFFDetailsResults[]): TSetFFDetails => ({
    type: SET_FF_DETAILS,
    data,
});

export const SetOwnFFPlans = (integrationId: string, data: TOwnFFPlanItem[]): TSetOwnFFPlans => ({
    type: SET_OWN_FF_PLANS,
    integrationId,
    data,
});

export const SetOwnFFAwdPlans = (integrationId: string, data: TOwnFFAwdPlanItem[]): TSetOwnFFAwdPlans => ({
    type: SET_OWN_FF_AWD_PLANS,
    integrationId,
    data,
});

export const getAllIntegrations = (cookies: Cookies): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingIntegrations(true));
            const response = await sendToAmazon.getAllIntegrations(cookies);
            dispatch(SetAllIntegrations(sortIntegrationList(response.data.results) || []));
            dispatch(SetLoadingIntegrations(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getReadyToAmazonProduct = (
    cookies: Cookies,
    integration_id: string,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            if (!offset) {
                dispatch(SetLoading(true));
                dispatch(SetCalculateShipmentPlan(null));
            }
            const response = await sendToAmazon.getAmazonProduct(cookies, integration_id, searchTerm, items, offset);
            setProductAllNext(response.data.next !== null ? true : false);
            dispatch(SetErrorAmazonProduct(null));
            dispatch(SetReadyToAmazonProduct(integration_id, response.data.results));
            if (!offset) {
                dispatch(SetLoading(false));
            }
        } catch (e) {
            console.log(e);
            dispatch(SetLoading(false));
        }
    };
};

export const getShipmentPlan = (cookies: Cookies, integration_id: string): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingShipmentPlans(true));
            const response = await sendToAmazon.getShipmentPlan(cookies, integration_id);
            const shipmentPlan = getState().sendToAmazon.shipmentPlan;
            if (shipmentPlan) {
                let filtered = shipmentPlan.filter((item) => item.integration_id !== integration_id);
                dispatch(SetShipmentPlan([...filtered, { plan_items: response.data, integration_id }]));
            } else {
                dispatch(SetShipmentPlan([{ plan_items: response.data, integration_id }]));
            }
            dispatch(SetLoadingShipmentPlans(false));
        } catch (e) {
            console.log(e);
            dispatch(SetLoadingShipmentPlans(false));
        }
    };
};

export const deleteShipmentPlan = (
    selected: string[],
    integrationId: string,
    cookies: Cookies,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await sendToAmazon.deleteShipmentPlan(selected, cookies);
            dispatch(getShipmentPlan(cookies, integrationId));
            dispatch(getReadyToAmazonProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "An error occurred while deleting the shipment plan", { isAWS: true });
            }
        }
    };
};

export const createShipmentPlan = (
    cookies: Cookies,
    sendToAmazonProduct: AmazonProduct[],
    integrationId: string,
    handleError: (data: string | string[]) => void,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            dispatch(SetLoadingMessage("We are creating your shipping plans. If you have lots of SKU’s this could take up to a minute."));
            const data: CreateShipmentPlanRequestType[] = [];
            sendToAmazonProduct.map((product) => {
                if (product.zero_number_of_cartons > 0) {
                    data.push({
                        template_carton_id: product.get_cartons_data_for_product_ship_to_amazon.template_carton_id,
                        sku: product.sku,
                        asin: product.asin,
                        number_of_units: product.zero_number_of_units,
                    });
                }
            });
            await sendToAmazon.createShipmentPlan(cookies, data, integrationId);
            dispatch(getShipmentPlan(cookies, integrationId));
            dispatch(getReadyToAmazonProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext)).then(() => {
                dispatch(SetLoadingMessage(null));
            });
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "An error occurred while creating the shipment plan", { isAWS: true });
                handleError(e.response.data);
                dispatch(getShipmentPlan(cookies, integrationId));
                dispatch(getReadyToAmazonProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext)).then(() => {
                    dispatch(SetLoadingMessage(null));
                });
            }
        }
    };
};

export const createCalculateShipmentPlan = (integrationId: string): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetCalculateShipmentPlan(integrationId));
        } catch (e) {
            console.log(e);
        }
    };
};

export const submitShipment = (
    cookies: Cookies,
    shipmentPlan: GetShipmentPlanResult[],
    integrationId: string,
    selectBoat: SelectBoatType,
    onSuccess: () => void
): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        const stateShipmentPlan = getState().sendToAmazon.shipmentPlan;

        try {
            dispatch(SetLoading(true));
            dispatch(SetLoadingMessage("We are submitting your shipping plans. If you have lots of SKU’s this could take up to a minute."));
            const data: SubmitShipmentRequestType[] = [];
            shipmentPlan.map((product) => {
                data.push({
                    shipment_id: product.shipment_id,
                    shipment_speed_type: selectBoat.shipment_speed_type,
                    last_mile_carrier: selectBoat.last_mile_carrier,
                    id: product.id,
                });
            });
            await sendToAmazon.submitShipment(cookies, { shipments: data, integration_id: integrationId, use_credits: selectBoat.use_credits });
            if (selectBoat.use_credits) {
                dispatch(getUser(cookies));
            }
            if (stateShipmentPlan?.length) {
                dispatch(SetShipmentPlan(stateShipmentPlan.map((item) => (item.integration_id === integrationId ? { ...item, plan_items: [] } : item))));
            }
            dispatch(SetCalculateShipmentPlan(null));
            onSuccess();
            dispatch(SetErrorAmazonProduct(false));
            dispatch(SetLoading(false));
            dispatch(SetLoadingMessage(null));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "An error occurred while submitting the shipping plan", { isAWS: true });
                dispatch(SetLoading(false));
                dispatch(getShipmentPlan(cookies, integrationId));
                dispatch(SetCalculateShipmentPlan(null));
                dispatch(SetLoadingMessage(null));
            }
        }
    };
};

export const consolidateShipmentPlans = (
    cookies: Cookies,
    shipmentPlan: TShipmentPlanRootObject,
    integrationId: string,
    handleError: (data: string | string[]) => void,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            dispatch(SetLoadingMessage("We consolidate your shipping plans. If you have a lot of items, it can take up to a minute."));

            await sendToAmazon.consolidateShipmentPlans(cookies, shipmentPlan.id, integrationId);
            dispatch(getShipmentPlan(cookies, integrationId));
            dispatch(getReadyToAmazonProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response && (e.response.status === 400 || e.response.status === 404 || e.response.status === 403)) {
                displayError(e.response.data, "An error occurred while creating the shipment plan");
                handleError(e.response.data);
                dispatch(getShipmentPlan(cookies, integrationId));
                dispatch(getReadyToAmazonProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext));
            }
        }
    };
};

export const getReadyToAmazonFFProduct = (
    cookies: Cookies,
    integration_id: string,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    component: TOwnFFSendToAmazonComponents
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            if (!offset && component === "off_amazon") {
                dispatch(SetLoading(true));
            }

            const response = await sendToAmazon.getAmazonProduct(cookies, integration_id, searchTerm, items, offset);

            setProductAllNext(response.data.next !== null ? true : false);

            dispatch(SetReadyToAmazonFFProduct(component, integration_id, response.data.results));

            if (!offset && component === "off_amazon") {
                dispatch(SetLoading(false));
            }
        } catch (e) {
            console.log(e);
            dispatch(SetLoading(false));
        }
    };
};

export const postCreateOwnFFOffAmazonPlan = (
    cookies: Cookies,
    formData: FormData,
    integrationId: string,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    onSuccess: () => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingMessage("We are sending your shipment information to the warehouse, if you have lots of SKUs this could take up to a minute."));
            dispatch(SetLoading(true));

            await sendToAmazon.postCreateOwnFFShipmentPlan(cookies, formData);

            onSuccess(); // clear changed products & clear all lazy items & reset lazy loading offset & reset all page changes

            await dispatch(getFFDetails(cookies));
            await dispatch(getReadyToAmazonFFProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext, "off_amazon"));
            dispatch(SetLoadingMessage(null));
            displaySuccess("Shipment sent to the warehouse successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "An error occurred while sending the shipment plan to the warehouse");
                dispatch(SetLoading(false));
                dispatch(SetLoadingMessage(null));
            }
        }
    };
};

export const getFFDetails = (cookies: Cookies): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingFFDetails(true));
            const response = await sendToAmazon.getFFDetails(cookies);
            dispatch(SetFFDetails(response.data.results));
            dispatch(SetLoadingFFDetails(false));
        } catch (e) {
            console.log(e);
            dispatch(SetLoadingFFDetails(false));
        }
    };
};

export const postCreateFlatFile = (cookies: Cookies, data: TPostCreateFlatFileData, onSuccess: () => void): SendToAmazonThunkType => {
    return async () => {
        try {
            await sendToAmazon.createFlatFile(cookies, data).then((res) => {
                window.open(res.data.url, "_blank");
                onSuccess();
            });
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "An error occurred while creating the flat file");
            }
        }
    };
};

export const getOwnFFPlans = (cookies: Cookies, integrationId: string): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            const response = await sendToAmazon.getOwnFFPlans(cookies, integrationId);

            dispatch(SetOwnFFPlans(integrationId, response.data.results));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const postCreateOwnFFPlan = (cookies: Cookies, data: TPostCreateOwnFFPlanData): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            await sendToAmazon.postCreateOwnFFPlan(cookies, data);

            dispatch(getOwnFFPlans(cookies, data.integration_id));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const deleteOwnFFPlans = (cookies: Cookies, integrationId: string, ids: string[]): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            await sendToAmazon.deleteOwnFFPlans(cookies, ids);

            await dispatch(getOwnFFPlans(cookies, integrationId)); // await is required for the correct order of actions in the function that calls this thunk
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const postOwnFFPlanDetails = (cookies: Cookies, integration_id: string, shipment_id: string): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            await sendToAmazon.postOwnFFPlanDetails(cookies, shipment_id);

            dispatch(getOwnFFPlans(cookies, integration_id));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                await dispatch(getOwnFFPlans(cookies, integration_id));
                displayError(e.response.data);
            }
        }
    };
};

export const postCreateOwnFFToAmazonPlan = (cookies: Cookies, integrationId: string, formData: FormData, onSuccess: () => void): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingMessage("We are sending your shipment information to the warehouse, if you have lots of SKUs this could take up to a minute."));
            dispatch(SetLoading(true));

            await sendToAmazon.postCreateOwnFFShipmentPlan(cookies, formData);

            onSuccess(); // reset own ff changes

            await dispatch(getFFDetails(cookies));
            await dispatch(getOwnFFPlans(cookies, integrationId));

            dispatch(SetLoading(false));
            dispatch(SetLoadingMessage(null));

            displaySuccess("Shipment sent to the warehouse successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                dispatch(SetLoadingMessage(null));
                displayError(e.response.data, "An error occurred while sending the shipment plan to the warehouse");
            }
        }
    };
};

export const postProcessOwnFFToAmazonLabels = (cookies: Cookies, integrationId: string, data: FormData, processStatus: (status: number) => void): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            const response = await sendToAmazon.postProcessOwnFFToAmazonLabels(cookies, data);

            await dispatch(getOwnFFPlans(cookies, integrationId));

            processStatus(response.status); // clear uploaded labels from state
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                processStatus(e.response.status); // change processing status
                displayError(e.response.data);
            }
        }
    };
};

// Own FF AWD shipments

export const getOwnFFAwdPlans = (cookies: Cookies, integrationId: string): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            const response = await sendToAmazon.getOwnFFAwdPlans(cookies, integrationId);

            dispatch(SetOwnFFAwdPlans(integrationId, response.data.results));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const deleteOwnFFAwdPlans = (cookies: Cookies, integrationId: string, idsToDelete: string[]): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            await sendToAmazon.deleteOwnFFAwdPlans(cookies, idsToDelete);

            await dispatch(getOwnFFAwdPlans(cookies, integrationId));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const postProcessOwnFFToAmazonAwdLabels = (cookies: Cookies, integrationId: string, formData: FormData): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingMessage("We are are processing your AWD labels, if you have lots of SKUs this could take up to a minute."));
            dispatch(SetLoading(true));

            await sendToAmazon.postProcessOwnFFToAmazonAwdLabels(cookies, formData);

            await dispatch(getOwnFFAwdPlans(cookies, integrationId));

            dispatch(SetLoading(false));
            dispatch(SetLoadingMessage(null));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                dispatch(SetLoadingMessage(null));
                displayError(e.response.data, "An error occurred while processing AWD labels");
            }
        }
    };
};

export const postSubmitOwnFFToAmazonAwdPlan = (cookies: Cookies, integrationId: string, formData: FormData, onSuccess: () => void): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingMessage("We are sending your shipment information to the warehouse, if you have lots of SKUs this could take up to a minute."));
            dispatch(SetLoading(true));

            await sendToAmazon.postCreateOwnFFShipmentPlan(cookies, formData);

            onSuccess(); // reset own ff changes

            await dispatch(getFFDetails(cookies));
            await dispatch(getOwnFFAwdPlans(cookies, integrationId));

            dispatch(SetLoading(false));
            dispatch(SetLoadingMessage(null));

            displaySuccess("Shipment sent to the warehouse successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                dispatch(SetLoadingMessage(null));
                displayError(e.response.data, "An error occurred while sending the shipment plan to the warehouse");
            }
        }
    };
};

export default sendToAmazonReducer;
